import React from "react";
import NewsCard from "../../components/NewsCard/NewsCard";
import {
  mediaHeading,
  mediaPartnerContainer,
  mediaArticleContainer,
  firstNews,
} from "./NewsCards.module.css";

export default function NewsCards() {
  const [partners, setPartners] = React.useState();
  const [ref, setRef] = React.useState();

  const getRef = () => {
    fetch("https://conquest.prismic.io/api/v2/", {
      method: "GET",
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            getData(res.data.refs[0].ref);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };

  const getData = (key) => {
    fetch(
      "https://conquest.prismic.io/api/v2/documents/search?ref=" +
        key +
        "&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ",
      {
        method: "GET",
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            setPartners(res.data.results);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };

  React.useEffect(() => {
    getRef();
  }, []);

  return (
    <>
      <div className={mediaPartnerContainer}>
        <div className={mediaArticleContainer}>
          {partners ? (
            <>
              {partners.map((p, id) => (
                <>
                  {p.type === "media" ? (
                    <>
                      {p.data.newscard.map((article, id) => (
                        <NewsCard
                          thumbnail={article.thumbnail.url}
                          readingTime={article.readingtime}
                          heading={article.heading[0].text}
                          date={article.date}
                          to={article.to.url}
                          logo={article.logo.url}
                        />
                      ))}
                    </>
                  ) : null}
                </>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
