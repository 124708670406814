import * as React from "react";
import {
    videoPlay,
    playImg,
    videoContainer,
    visionBox,
    visionTextBox,
    visionTextH,
    visionTextDesc,
    videoBox,
    videoText,
    videoWhat,
    videoConquest,
} from "./CNBC.module.css";

const CNBCtelecast = () => {
  return (
    <div class={visionBox}>
        <div className={videoContainer}>
            <a
                href="https://www.youtube.com/watch?v=I61yzJqzbf0"
                target="_blank"
                rel="noreferrer"
            >
                <div className={videoPlay}>
                <img
                    alt=""
                    className={playImg}
                    src="https://i.imgur.com/ZFPUx6a.png"
                />
                </div>
            </a>  
        </div>
        <div class={visionTextBox}>
          <div class={visionTextH}>30-minute Conquest Special on CNBC-TV18</div>
        </div>
    </div>
    

  );
};
export default CNBCtelecast;