import * as React from "react";
import Footer from "../sections/Footer/Footer";
import { Helmet } from "react-helmet";
import NewsCards from "../sections/NewsCards/NewsCards";
import Navbar from "../sections/Navbar/Navbar";
import CNBC from "../sections/CNBC/CNBC";
import RegBanner from "../sections/RegBanner/RegBanner";
import NetworkHero from "../sections/NetworkHero/NetworkHero";
import MediaPartnersLogo from "../sections/MediaPartnersLogos/MediaPartnersLogo";

import "../fonts/stylesheet.css";

// styles
const text = {
  fontFamily: "Manrope",
};

// markup
const MediaPage = () => {
  return (
    <main style={text}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <Navbar />
      <NetworkHero
        img1="https://images.prismic.io/conquest/a4444485-630d-40e1-b6f8-ef2d3a4880cb_inc42.png?auto=compress,format"
        img2="https://images.prismic.io/conquest/1622f531-ac09-41a8-b548-370aad0b4266_toi.png?auto=compress,format"
        img3="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimage3.mouthshut.com%2Fimages%2Fimagesp%2F925001980s.jpg&f=1&nofb=1"
        img4="https://images.prismic.io/conquest/27ce0d76-bc70-4410-95fb-1be0e8e9883b_yourstory.png?auto=compress,format"
        img5="https://images.prismic.io/conquest/f11f68c2-1daa-4fc8-bfb0-7da5512881e8_economictimes.png?auto=compress,format"
        img6="https://images.prismic.io/conquest/7bdf5aaa-d827-4295-919b-f07dcfdb5e19_dailyhunt.png?auto=compress,format"
        meet="conquest"
        context="in the news"
        desc="Over the years, Conquest has been featured in various media articles. Have a look at what they have to say about Conquest."
      />
      <CNBC />
      <NewsCards />
      <RegBanner />
      <Footer />
    </main>
  );
};

export default MediaPage;
